<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
                <el-breadcrumb-item>系统管理</el-breadcrumb-item>
                <el-breadcrumb-item>用户管理</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>

        <el-col :span="24" class="warp-main">
            <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
                <el-form :inline="true" :model="filters">
                    <el-form-item>
                        <el-input v-model="filters.username" placeholder="用户名"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-on:click="getUsers(0)" icon="search">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="showAddDialog" v-if="permissionButton.add" icon="plus">新增
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-table :data="users" highlight-current-row v-loading="listLoading" @selection-change="selsChange"
                      style="width: 100%;" border>
                <!--<el-table-column type="selection" width="50" align="center"></el-table-column>-->
                <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="username" label="用户名" width="150" align="center"></el-table-column>
                <!--<el-table-column prop="password" label="密码" width="120" align="center"></el-table-column>-->
                <el-table-column prop="name" label="姓名" width="100" align="center"></el-table-column>
                <el-table-column prop="sex" label="性别" width="80" align="center"
                                 :formatter="formatSex"></el-table-column>
                <!--<el-table-column prop="age" label="年龄" width="80" align="center"></el-table-column>-->
                <el-table-column prop="roleName" label="角色" width="200" align="center"></el-table-column>
                <el-table-column prop="dept" label="部门" min-width="300" align="center"></el-table-column>
                <el-table-column prop="post" label="职务" width="200" align="center"></el-table-column>
                <el-table-column prop="number" label="编号" width="100" align="center" sortable></el-table-column>
                <el-table-column prop="formatCreateTime" label="创建时间" width="180" align="center" sortable>
                </el-table-column>
                <el-table-column label="操作" width="150" align="center" fixed="right">
                    <template slot-scope="scope" v-if="scope.row.roleId!==1">
                        <el-button size="small" @click="showEditDialog(scope.$index,scope.row)"
                                   v-if="permissionButton.edit">编辑
                        </el-button>
                        <el-button type="danger" @click="delUser(scope.$index,scope.row)" size="small"
                                   v-if="permissionButton.del">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-col :span="24" class="table-footer">
                <!--<el-button type="danger" size="small" @click="batchDeleteAdminUser" :disabled="this.sels.length===0" v-if="permissionButton.batchDel">批量删除</el-button>-->
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :page-sizes="[10,15,20,25,50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total" style="float:right;">
                </el-pagination>
            </el-col>

            <el-dialog title="新增" :visible.sync="addFormVisible" width="30%" :close-on-click-modal="false"
                       @open="open('addDialog')">
                <el-form :model="addForm" label-width="80px" :rules="addFormRules" ref="addForm">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="addForm.username" placeholder="请输入用户名" maxlength="50"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input type="password" v-model="addForm.password" placeholder="请输入密码(字母+数字6-16个字符)"
                                  maxlength="16"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" prop="roleId" id="roleId">
                        <el-radio-group v-model="addForm.roleId">
                            <el-radio v-for="item in roleArray" :label="item.roleId" :key="item.roleId">
                                {{item.roleName}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="addForm.name" placeholder="请输入姓名" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex">
                        <el-radio-group v-model="addForm.sex">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!--<el-form-item label="年龄" prop="age">-->
                    <!--<el-input v-model.number="addForm.age" placeholder="请输入年龄"></el-input>-->
                    <!--</el-form-item>-->
                    <el-form-item label="部门" prop="dept">
                        <el-input v-model="addForm.dept" placeholder="请输入部门" maxlength="50"></el-input>
                    </el-form-item>
                    <el-form-item label="职务" prop="post">
                        <el-input v-model="addForm.post" placeholder="请输入职务" maxlength="50"></el-input>
                    </el-form-item>
                    <el-form-item label="编号" prop="number">
                        <el-input v-model.number="addForm.number" placeholder="请输入编号" maxlength="11"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click.native="addFormVisible = false">取消</el-button>
                    <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
                </div>
            </el-dialog>

            <el-dialog title="编辑" :visible.sync="editFormVisible" width="30%" :close-on-click-modal="false"
                       @open="open('editDialog',editForm)" @close="closeDialog">
                <!--<el-form :model="editForm" label-width="100px" :rules="editFormRules" ref="editForm" >-->
                <el-form :model="editForm" label-width="150px" :rules="editFormRulesPwd" ref="editForm">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="editForm.username" :disabled="true" placeholder="请输入用户名"
                                  maxlength="50"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password"
                                  :rules="editForm.password?editFormRulesPwd.password:[{ required: false}]">
                        <el-input type="password" v-model="editForm.password" placeholder="若不修改,保存为原密码."
                                  maxlength="16"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" prop="roleId">
                        <el-radio-group v-model="editForm.roleId">
                            <el-radio v-for="item in roleArray" :label="item.roleId" :key="item.roleId">
                                {{item.roleName}}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="editForm.name" placeholder="请输入姓名" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex" class="is-required">
                        <el-radio-group v-model="editForm.sex">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <!--<el-form-item label="年龄" prop="age">-->
                    <!--<el-input v-model.number="editForm.age" placeholder="请输入年龄" maxlength="30"></el-input>-->
                    <!--</el-form-item>-->
                    <el-form-item label="部门" prop="dept">
                        <el-input v-model="editForm.dept" placeholder="请输入部门" maxlength="50"></el-input>
                    </el-form-item>
                    <el-form-item label="职务" prop="post">
                        <el-input v-model="editForm.post" placeholder="请输入职务" maxlength="50"></el-input>
                    </el-form-item>
                    <el-form-item label="编号" prop="number">
                        <el-input v-model.number="editForm.number" placeholder="请输入编号" maxlength="11"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click.native="editFormVisible = false">取消</el-button>
                    <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
                </div>
            </el-dialog>

        </el-col>
    </el-row>
</template>
<script>
    import permission from '../../../common/permission';
    import {reqGetUserListPage, reqDeleteUser, reqEditUser,reqAddUser, reqGetRoleListAll,} from '../../../api/system-api'

    export default {
        data() {

            var checkAge = (rule, value, callback) => {
                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数值'));
                } else if (value.toString().length > rule.length || value.toString().length < 0) {
                    callback(new Error('年龄不能超过' + rule.length + '个字符'));
                } else {
                    if (value < 0 || value > 150) {
                        callback(new Error('年龄不能超过150岁'));
                    } else {
                        callback();
                    }
                }
            };


            var checkNumber = (rule, value, callback) => {
                if (value.toString().length == 0) {
                    return callback(new Error('编号不能为空'));
                }
                if (!Number.isInteger(value) || value < 0) {
                    callback(new Error('请输入正整数值'));
                } else {
                    callback();
                }
            };
            return {
                filters: {
                    username: '',
                },
                users: [],
                showAddBusiness: false,
                showEditBusiness: false,
                total: 0,
                pageNum: 1,
                pageSize: 15,
                listLoading: false,
                sels: [],
                roleArray: [],
                addFormVisible: false,//新增界面是否显示
                addLoading: false,
                addFormRules: {
                    username: [
                        {required: true, message: '请输入用户名', trigger: 'blur'},
                        {min: 1, max: 50, message: '用户名不能超过50个字符', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {
                            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                            message: '密码由字母和数字组成6到16个字符',
                            trigger: 'blur'
                        },
                    ],
                    roleId: [
                        {required: true, type: 'number', message: '请输入角色', label: '角色'},
                    ],
                    name: [
                        {required: true, message: '请输入姓名', trigger: 'blur'},
                        {min: 1, max: 20, message: '用户名20个字符', trigger: 'blur'},
                    ],
                    sex: [
                        {required: true}
                    ],
                    // age: [
                    //   {required: true,validator: checkAge, trigger: 'blur',length: 3},
                    // ],
                    dept: [
                        {required: true, message: '请输入部门'},
                    ],
                    post: [
                        {required: true, message: '请输入职务', trigger: 'blur'},
                    ],
                    number: [
                        {required: true, validator: checkNumber, trigger: 'blur'},
                    ],
                },
                addForm: {
                    username: '',
                    password: '',
                    roleId: '',
                    name: '',
                    sex: 1,
                    age: '',
                    dept: '',
                    post: '',
                    number: '',
                },
                editFormVisible: false,//编辑界面是否显示
                editLoading: false,
                editFormRulesPwd: {
                    username: [
                        {required: true, message: '请输入用户名', trigger: 'blur'},
                        {min: 1, max: 50, message: '用户名不能超过50个字符', trigger: 'blur'},
                    ],
                    password: [
                        {
                            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                            message: '密码由字母和数字组成6到16个字符',
                            trigger: 'blur'
                        },
                    ],
                    roleId: [
                        {required: true, type: 'number', message: '请输入角色', label: '角色'},
                    ],

                    name: [
                        {required: true, message: '请输入姓名', trigger: 'blur'},
                        {min: 1, max: 20, message: '用户名20个字符', trigger: 'blur'},
                    ],
                    age: [
                        {required: true, validator: checkAge, trigger: 'blur', length: 3},
                    ],
                    dept: [
                        {required: true, message: '请输入部门'},
                    ],
                    post: [
                        {required: true, message: '请输入职务', trigger: 'blur'},
                    ],
                    number: [
                        {required: true, validator: checkNumber, trigger: 'blur'},
                    ],
                },
                editForm: {
                    id: 0,
                    username: '',
                    password: '',
                    roleId: '',
                    name: '',
                    sex: 1,
                    age: '',
                    dept: '',
                    post: '',
                    number: '',
                },
                permissionButton: new permission.PermissionButton
            }
        },
        computed: {
            addRoleId() {
                return this.addForm.roleId
            },
            editRoleId() {
                return this.editForm.roleId
            }
        },
        watch: {
            addRoleId(newValue) {
                if (newValue === 3) {
                    this.showAddBusiness = true;
                } else {
                    this.showAddBusiness = false;
                }
            },
            editRoleId(newValue) {
                if (newValue === 2) {
                    this.showEditBusiness = false;
                } else {
                    this.showEditBusiness = true;
                }
            }
        },
        methods: {
            formatSex: function (row) {
                return parseInt(row.sex) == 1 ? '男' : parseInt(row.sex) == 2 ? '女' : '未知';
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.currentPage = val;
                this.getUsers();
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.getUsers();
            },
            goReset() {
                this.filters.username = '';
                this.pageNum = 1;
                this.getUsers();
            },
            getUsers(status) {
                let para = {
                    pageNum: status===0?0:this.pageNum,
                    pageSize: this.pageSize,
                    username: this.filters.username,
                };
                this.listLoading = true;
                reqGetUserListPage(para).then((res) => {
                    this.total = res.data.data.total;
                    this.users = res.data.data.records;
                    this.listLoading = false;
                });
            },
            selsChange: function (sels) {
                this.sels = sels;
            },
            showAddDialog: function () {
                this.addFormVisible = true;
                console.log(this.addForm)
            },
            addSubmit: function () {
                this.$refs.addForm.validate((valid) => {
                    if (valid) {
                        this.addLoading = true;
                        let para = Object.assign({}, this.addForm);
                        reqAddUser(para).then((res) => {
                            this.addLoading = false;
                            if (res.data.code === 1) {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success'
                                });
                                this.$refs['addForm'].resetFields();
                                this.addFormVisible = false;
                                this.getUsers();
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: 'error'
                                });
                            }
                        }).catch(() => {
                            this.addLoading = false;
                            this.$message({
                                message: "提交失败",
                                type: 'error'
                            });
                        })
                    }
                });
            },
            //显示编辑界面
            showEditDialog: function (index, row) {
                this.editFormVisible = true;
                row.password = "";
                this.editForm = Object.assign({}, row);
            },
            closeDialog: function () {
//        this.showEditBusiness=false;
//        this.editForm={};
            },
            //编辑
            editSubmit: function () {
                this.$refs.editForm.validate((valid) => {
                    if (valid) {
                        this.$confirm('确认提交吗？', '提示', {}).then(() => {
                            this.editLoading = true;
                            delete this.editForm['createTime'];
                            let para = Object.assign({}, this.editForm);
                            reqEditUser(para).then((res) => {
                                this.editLoading = false;
                                if(res.data.code===1){
                                    this.$message({
                                        message: '提交成功',
                                        type: 'success'
                                    });
                                    this.$refs['editForm'].resetFields();
                                    this.editFormVisible = false;
                                    this.getUsers();
                                }else{
                                    this.$message({
                                        message: '提交失败',
                                        type: 'success'
                                    });
                                }

                            });
                        }).catch(() => {
                            this.editLoading = false;
                            this.$message({
                                message: '提交失败',
                                type: 'success'
                            });
                        })
                    }
                });
            },
            //删除
            delUser: function (index, row) {
                this.$confirm('确认删除该记录吗?', '提示', {type: 'warning'}).then(() => {
                    this.listLoading = true;
                    let para = {id: row.id};
                    reqDeleteUser(para).then(() => {
                        this.listLoading = false;
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.getUsers();
                    });
                }).catch(() => {
                    this.listLoading = false;
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                });
            },
            getRoleListAll: function () {
                let that = this;
                reqGetRoleListAll({}).then((res) => {
                    let roleArray = res.data.data;
                    roleArray.splice(0, 1);
                    that.roleArray = roleArray;
                });
            },
            open: function () {
            },
        },
        mounted() {
            var permissionId = this.$route.query.permissionId;
            permission.getPermissionButton(permissionId, this.permissionButton);
            this.getRoleListAll();
            this.getUsers();
        }
    }
</script>

